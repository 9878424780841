export const SITUACAO_EMPRESA = [
  { id: "ATIVA", nome: "Ativa" },
  { id: "INATIVA", nome: "Inativa" },
];

export const TIPO_SERVICO = [
  { id: "FORNECEDOR", nome: "Fornecedor" },
  { id: "FORNECEDOR/DISTRIBUIDOR", nome: "Fornecedor/Distribuidor" },
  { id: "ARMAZEM/DISTRIBUIDOR", nome: "Armazém/Distribuidor" },
];

export const TIPO_FORNECEDOR = [
  { id: "CONVENCIONAL", nome: "Convencional" },
  { id: "AGRICULTURA_FAMILIAR", nome: "Agricultura familiar" },
];
