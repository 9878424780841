import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { getEmpresasLookupCompleto } from "../../../service/Empresas.service";

export class SelecionaEmpresa extends Component {
  constructor() {
    super();
    this.state = {
      empresas: [],
    };
  }

  async componentDidMount() {
    this.buscaEmpresas();
  }

  selecionaEmpresa(event) {
    this.props.onSelect(event.value);
  }

  async buscaEmpresas() {
    const empresas = await getEmpresasLookupCompleto();
    this.setState({ empresas });
  }

  render() {
    return (
      <Dropdown
        {...this.props}
        optionLabel={this.props.campo}
        options={this.state.empresas}
        value={this.props.empresa}
        onChange={event => this.selecionaEmpresa(event)}
        autoWidth={false}
        placeholder="Selecione uma Empresa"
        showClear={true}
        emptyFilterMessage={"Nenhuma empresa encontrada"}
      />
    );
  }
}
