export const HOME = "/";
export const LISTA_CONTRATOS = "/lista_contratos";
export const PAINEL_SELECAO = "/painel-selecao";
export const CONTRATOS_CONTINUOS = "/contratos-continuos";
export const VISUALIZAR_CONTRATOS = "/visualizar-contrato";
export const ATRIBUICAO_TERMO_CONTRATO = "/atribuicao-termo-contrato";
export const GESTAO_CONTRATOS = "/gestao-contratos";
export const LISTAR_ATAS = "/listar-atas";
export const ATAS = "/atas";
export const EMPRESAS = "/empresas";
export const LISTAR_EMPRESAS = "/listar-empresas";
export const LISTAR_PRODUTOS = "/listar-produtos";
export const PRODUTOS = "/produtos";
export const CADASTRAR_CONTRATO = "/cadastro-unico-contrato";
export const EDITAL = "/edital";
export const LISTAR_EDITAIS = "/listar-editais";
export const TESTE = "/teste";
export const ORCAMENTO = "/orcamento";
export const DOTACAO_ORCAMENTARIA = "/dotacao-orcamentaria";
