export const CATEGORIA_PRODUTO = [
  { id: "ALIMENTO", nome: "Alimento" },
  { id: "OUTROS", nome: "Outro(s)" },
];

export const SITUACAO_PRODUTO = [
  { id: "ATIVO", nome: "Ativo" },
  { id: "INATIVO", nome: "Inativo" },
];

export const GRUPO_ALIMENTAR_PRODUTO = [
  { id: "CONGELADOS_E_RESFRIADOS", nome: "Congelados e Resfriados" },
  { id: "FLVO", nome: "FLVO" },
  { id: "PAES_E_BOLO", nome: "Pães e Bolos" },
  { id: "SECOS", nome: "Secos" },
];

export const TIPO_PROGRAMA = [
  { id: "ALIMENTACAO_ESCOLAR", nome: "Alimentação Escolar" },
  { id: "LEVE_LEITE", nome: "Leve Leite" },
];
