export const UNIDADES_VIGENCIA = [
  { id: "DIAS", nome: "dias" },
  { id: "MESES", nome: "meses" },
];

export const STATUS_ATA = [
  { id: "ATIVA", nome: "Ativa" },
  { id: "ENCERRADA", nome: "Encerrada" },
  { id: "RASCUNHO", nome: "Rascunho" },
];
